<template>
  <div>
    <div class="avue-el-dropdown" :class="randomClassName">
      <!-- 
        下拉菜单:
          数据类型 - [{id: '187846144848',prjName: '项目名称 1'}]
      -->
      <el-dropdown v-if="links.length != 0 && !option.switchSelected" @command="handleCommand">
        <span class="el-dropdown-link" :style="elDropdownLinkStyle"> {{ option.linkText }} <i class="el-icon-arrow-down el-icon--right"></i> </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="item in links" :key="item.id" :command="item.id">{{ item.prjName }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <!-- 
        下拉选择器:
          数据类型 - [{value: 1,name: '选择 1'}]
      -->
      <el-select
        v-else-if="option.switchSelected"
        v-model="selectValue"
        placeholder="请选择"
        @change="handleChange"
        :popper-class="'avue-el-dropdown-popper-' + randomClassName">
        <el-option v-for="item in links" :key="item.value" :label="item.name" :value="item.value">
        </el-option>
      </el-select>

      <!-- 
        切换全屏的按钮
      -->
      <template v-else-if="option.switchFullScreenBtn">
        <el-tooltip class="item" effect="dark" :content="isFullScreen ? '退出全屏' : '进入全屏' " placement="top">
          <div class="fullscreen-btn-wrapper btn" @click="toggleFullScreen">
            <i v-if="isFullScreen" class="el-icon-crop"></i>
            <i v-else class="el-icon-full-screen"></i>
          </div>
        </el-tooltip>
      </template>

    </div>
  </div>
</template>

<script>
import http from '@/utils/httpRequest'

export default {
  name: 'dropdown',
  props: {
    option: Object,
    component: Object,
  },
  data() {
    return {
      selectValue: 1,

      // 链接
      links: [],

      isFullScreen: false,

      fullscreenStyle: null
    }
  },
  computed: {
    // 随机类名称 限定样式作用范围使用
    randomClassName() {
      return 'avue-el-dropdown-' + String(new Date().getTime()).slice(5) + String(Math.round(Math.random() * 10e8))
    },
    // 下拉字体的样式
    elDropdownLinkStyle() {
      return {
        'font-size': this.option.linkFontSize + 'px',
        color: this.option.linkFontColor
      }
    },
    selectInputStyle() {
      return this.option.selectInputStyle
    },
    popperInputStyle() {
      return this.option.popperInputStyle
    },
    // 全屏按钮
    fullScreenBtnStyle() {
      return this.option.fullScreenBtnStyle
    }
  },
  watch: {
    dataChart() {
      this.$set(this, 'links', this.dataChart)
    },
    selectInputStyle: {
      immediate: true,
      handler(value) {
        // 输入的样式改变 设置样式
        if (!(value && this.option.switchSelected)) return
        // 存在则添加样式
        const querySelector = `.avue-el-dropdown.${this.randomClassName} .el-select .el-input>.el-input__inner`
        const cssCode = `${querySelector} {${value}}`
        this.loadCssCode(cssCode)
      }
    },
    // 选择器的弹出框的样式修改
    popperInputStyle: {
      immediate: true,
      handler(value) {
        if (!(value && this.option.switchSelected)) return
        const parentSelector = '.el-popper.el-select-dropdown.avue-el-dropdown-popper-' + this.randomClassName
        const cssCode = this.createCssCode(parentSelector, value)
        // 添加样式
        this.loadCssCode(cssCode)
      }
    },

    // 选择器的弹出框的样式修改
    fullScreenBtnStyle: {
      immediate: true,
      handler(value) {
        if (!(value && this.option.switchFullScreenBtn)) return
        const parentSelector = '.fullscreen-btn-wrapper.btn'
        const cssCode = this.createCssCode(parentSelector, value)
        // 添加样式
        this.loadCssCode(cssCode)
      }
    },

  },
  methods: {
    // 点击切换全屏
    toggleFullScreen() {
      if (this.isFullScreen) {
        this.exitScreen()
      } else {
        this.enterScreen()
      }
    },
    // 下拉选择器选择改变回调函数
    handleChange(value) {
      this.handleNameSpace(value)
    },
    // 点击事件
    handleCommand(command) {
      const evalFunc = new Function('command', this.option.evalFunction)
      evalFunc(command)
    },

    // 处理命名空间的存在的情况
    // 如果存在命名空间 将值写入
    // 定义的命令空间格式 {value: Number,data: Object}
    handleNameSpace(value) {

      if (!this.option.nameSpace || !this.option.nsapi) {
        return
      }

      // 有设置命名空间
      const ns = this.option.nameSpace
      // 请求数据
      const api = this.option.nsapi

      if (!this.BINAMESPACE[ns]) {
        this.BINAMESPACE[ns] = {}
      }

      // 设置值
      this.BINAMESPACE[ns].value = value

      // 创建请求
      const params = {
        range: value,
        ...this.$route.query,
        ...window.getQuerys(api)
      }
      const request = () => {
        return http({
          url: http.adornUrl(api.split('?')[0]),
          method: 'get',
          params: params
        })
      }

      // 请求
      request()
        .then(({ data }) => {
          if (data.code != 0) {
            this.$message.error(data.msg)
            return
          }
          // 成功
          this.BINAMESPACE[ns].data = data.data
          window.BIDATA.BINS[ns].updateNsData()
        })
        .catch(() => {
          this.$message.error('请求数据出现错误')
        })

    },

    // 在html中添加css
    loadCssCode(code) {
      const style = document.createElement('style')
      style.type = 'text/css'
      style.rel = 'stylesheet'
      try {
        // for Chrome Firefox Opera Safari
        style.appendChild(document.createTextNode(code))
      } catch (ex) {
        // for IE
        style.styleSheet.cssText = code
      }
      const head = document.getElementsByTagName('head')[0]
      head.appendChild(style)
      return style
    },

    /**
     * 分割样式添加父选择器
     */
    createCssCode(parentSelector, value) {
      // 分割样式块
      const styles = value.split('|')
      let cssCode = ''
      styles.forEach(code => {
        cssCode += (parentSelector + ' ' + code)
      })
      return cssCode
    },

    // 进入全屏
    enterScreen() {
      const element = document.documentElement
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen()
      }

      // 判断是否成功
      setTimeout(() => {
        if (this.isFullscreenForNoScroll()) {
          this.isFullScreen = true
        }
      }, 200);

    },
    //退出全屏
    exitScreen() {
      if (document.exitFullscreen || document.documentElement.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }

      // 判断是否成功
      setTimeout(() => {
        if (!this.isFullscreenForNoScroll()) {
          this.isFullScreen = false
        } else {
          this.$message.warning('使用F11/ESC键退出全屏')
        }
      }, 20);

    },

    /**
     * 切换html全屏的溢出滚动条样式
     * ----------------------
     * true - 添加
     * false - 删除
     */
    toggleOverFlow(flag) {
      // 添加
      if (flag) {
        if (this.fullscreenStyle) {
          this.fullscreenStyle.remove()
          this.fullscreenStyle = null
        }
        const cssCode = `
          body div.bi-container-content {
            overflow: hidden hidden !important;
          }
        `
        this.fullscreenStyle = this.loadCssCode(cssCode)
        // console.log('[添加样式] 样式 ---> ', this.fullscreenStyle)
        return
      }

      // 删除
      if (this.fullscreenStyle) {
        this.fullscreenStyle.remove()
        this.fullscreenStyle = null
      }

    },

    /**
     * 判断是否全屏
     * 将浏览器的可视大小和电脑的屏幕大小作比较的得出的
     */
    isFullscreenForNoScroll() {
      var explorer = window.navigator.userAgent.toLowerCase();
      if (explorer.indexOf('chrome') > 0) { //webkit
        if (document.body.scrollHeight === window.screen.height && document.body.scrollWidth === window.screen.width) {
          // console.log("isFullscreenForNoScroll ---> 全屏");
          return true
        } else {
          // console.log("isFullscreenForNoScroll ---> 不全屏");
          return false
        }
      } else { //IE 9+  fireFox
        if (window.outerHeight === window.screen.height && window.outerWidth === window.screen.width) {
          // console.log("isFullscreenForNoScroll ---> 全屏");
          return true
        } else {
          // console.log("isFullscreenForNoScroll ---> 不全屏");
          return false
        }
      }
    },

    /**
     * 添加全屏的样式
     */
    enterFullScreenStyle() {
      this.isFullScreen = this.isFullscreenForNoScroll()
      if (this.isFullScreen) {
        this.toggleOverFlow(true)
      } else {
        this.toggleOverFlow(false)
      }
    },

  },
  mounted() {

    // 默认区划代码 --- 设置到BIDATA中
    if (this.option.defaultAreaCode) {
      window.BIDATA.defaultAreaCode = this.option.defaultAreaCode
    }

    // 选择器 --- 将设置的命名空间的第一个值设置到BIDATA中
    if (
      this.option.switchSelected && this.option.nameSpace &&
      this.data && Array.isArray(this.data) && this.data.length > 0
    ) {
      let nameSpace = window.BIDATA.BINAMESPACE[this.option.nameSpace]
      if (!nameSpace) {
        nameSpace = {}
        window.BIDATA.BINAMESPACE[this.option.nameSpace] = nameSpace
      }
      nameSpace.value = this.data[0].value
      this.selectValue = this.data[0].value
    }

    // 全屏按钮 --- 检测是否是全屏按钮,添加监听器监听全屏
    if (this.option.switchFullScreenBtn) {
      this.enterFullScreenStyle()
      // 窗口改变的事件监听器
      window.addEventListener("resize", (event) => {
        this.enterFullScreenStyle()
      });
    }

  },
}
</script>

<style lang="scss" scoped>
.avue-el-dropdown ::v-deep {
  padding: 10px;
  overflow: hidden;

  .el-dropdown .el-dropdown-link.el-dropdown-selfdefine {
    color: white;
  }
}

.el-dropdown-menu.el-popper {
  min-height: 400px;
  height: 50vh;
  overflow: hidden scroll;
}
</style>
